// Variabili
$profile_pic_size: 200px;

// Regole custom per il social 
.profile-pic
{
    width: 100%;
    max-width: $profile_pic_size;
    height: auto;
    max-height: $profile_pic_size;
}   

.profile-pic.small {
  width: 100%;
  max-width: 24px;
  height: auto;
  max-height: 24px;
}


// image-uploader-previewer.js
.btn-photo
{
    position: relative;
    width: 240px;
    height: 240px;
}
.btn-photo img
{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 4px;
    width: auto;
}


// Posts
.comments
{
    background: #f5f5f5;
    border-top: 1px solid #d3e0e9; 
    padding: 4px; 
}

// Nested comments
.nested-comments
{
    background: #f5f5f5;  
    border-top: none;
    margin-left: 24px; 
    margin-top: 4px;
} 
.comments > .comment,
.nested-comments > .comment
{
    background-color: #fff;
    padding: 8px;
    border-radius: 3px; 
}

// Nasconde i .no-comments se si trovano dopo un commento
.comment ~ .no-comments,
.comments ~ .no-comments,
.nested-comments ~ .no-comments,
{
  display: none;
}